@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800";
body {
  color: #677284;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

a:focus, input:focus, textarea:focus, button:focus {
  outline: none;
  text-decoration: none;
}

a:focus, a:hover {
  text-decoration: none;
}

i, span, a {
  display: inline-block;
}

a, a:hover {
  color: #8b9474;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

h1, h2, h3, h4, h5, h6 {
  color: #9f8768;
  margin: 0;
  font-family: Poppins, sans-serif;
  font-weight: 700;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

p b {
  color: #8b9474;
}

p {
  color: #000;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@media only screen and (width <= 768px) {
  .bg_cover {
    background-size: contain;
    height: 67vw !important;
  }
}

.bg_cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.main-btn {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  -o-transition: all .4s ease-out 0s;
  background-color: #8b9474;
  border: 0;
  border-radius: 5px;
  padding: 0 40px;
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  transition: all .4s ease-out;
  display: inline-block;
}

.main-btn:hover {
  color: #fff;
  background-color: #ffb316cc;
}

.main-btn.main-btn-2 {
  color: #8b9474;
  background-color: #fff;
  border-color: #8b9474;
}

.main-btn.main-btn-2:hover {
  color: #fff;
  background-color: #8b9474;
  border-color: #8b9474;
}

.section_title .title {
  font-size: 40px;
  font-weight: 700;
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .section_title .title {
    font-size: 32px;
  }
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .section_title .title {
    font-size: 34px;
  }
}

@media (width <= 767px) {
  .section_title .title {
    font-size: 24px;
  }
}

.section_title.section_title_2 .title {
  color: #fff;
}

.slick-slide {
  outline: 0;
}

.preloader {
  z-index: 99999;
  background: #fff;
  width: 100%;
  height: 100%;
  display: table;
  position: fixed;
  top: 0;
  left: 0;
}

.preloader .loader {
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}

.preloader .loader .ytp-spinner {
  z-index: 18;
  pointer-events: none;
  width: 64px;
  margin-left: -32px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.preloader .loader .ytp-spinner .ytp-spinner-container {
  pointer-events: none;
  width: 100%;
  margin-top: -50%;
  margin-left: -50%;
  padding-bottom: 100%;
  animation: 1.56824s linear infinite ytp-spinner-linspin;
  position: absolute;
  top: 50%;
  left: 50%;
}

.preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator {
  width: 100%;
  height: 100%;
  animation: 5.332s cubic-bezier(.4, 0, .2, 1) infinite both ytp-spinner-easespin;
  position: absolute;
}

.preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator .ytp-spinner-left {
  position: absolute;
  inset: 0 50% 0 0;
  overflow: hidden;
}

.preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator .ytp-spinner-right {
  position: absolute;
  inset: 0 0 0 50%;
  overflow: hidden;
}

.preloader .loader .ytp-spinner-circle {
  box-sizing: border-box;
  border: 6px solid #8b9474;
  border-bottom-color: #f7f7fd;
  border-radius: 50%;
  width: 200%;
  height: 100%;
  position: absolute;
}

.preloader .loader .ytp-spinner-left .ytp-spinner-circle {
  border-right-color: #f7f7fd;
  animation: 1.333s cubic-bezier(.4, 0, .2, 1) infinite both ytp-spinner-left-spin;
  left: 0;
  right: -100%;
}

.preloader .loader .ytp-spinner-right .ytp-spinner-circle {
  border-left-color: #f7f7fd;
  animation: 1.333s cubic-bezier(.4, 0, .2, 1) infinite both ytp-right-spin;
  left: -100%;
  right: 0;
}

@keyframes ytp-spinner-linspin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ytp-spinner-easespin {
  12.5% {
    transform: rotate(135deg);
  }

  25% {
    transform: rotate(270deg);
  }

  37.5% {
    transform: rotate(405deg);
  }

  50% {
    transform: rotate(540deg);
  }

  62.5% {
    transform: rotate(675deg);
  }

  75% {
    transform: rotate(810deg);
  }

  87.5% {
    transform: rotate(945deg);
  }

  to {
    transform: rotate(1080deg);
  }
}

@keyframes ytp-spinner-left-spin {
  0% {
    transform: rotate(130deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  to {
    transform: rotate(130deg);
  }
}

@keyframes ytp-right-spin {
  0% {
    transform: rotate(-130deg);
  }

  50% {
    transform: rotate(5deg);
  }

  to {
    transform: rotate(-130deg);
  }
}

.header_navbar {
  z-index: 99;
  -o-transition: all .3s ease-out 0s;
  width: 100%;
  transition: all .3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

.sticky {
  z-index: 99;
  -o-transition: all .3s ease-out 0s;
  background-color: #fff;
  transition: all .3s ease-out;
  position: fixed;
  box-shadow: 0 20px 50px #0000000d;
}

.sticky .navbar {
  padding: 15px 0;
}

.navbar {
  -o-transition: all .3s ease-out 0s;
  border-radius: 5px;
  padding: 20px 0;
  transition: all .3s ease-out;
  position: relative;
}

.navbar-brand {
  padding: 0;
}

.navbar-brand img {
  width: 150px;
}

.navbar-toggler {
  padding: 0;
}

.navbar-toggler .toggler-icon {
  -o-transition: all .3s ease-out 0s;
  background-color: #fff;
  width: 30px;
  height: 2px;
  margin: 5px 0;
  transition: all .3s ease-out;
  display: block;
  position: relative;
}

.navbar-toggler.active .toggler-icon:first-of-type {
  top: 7px;
  transform: rotate(45deg);
}

.navbar-toggler.active .toggler-icon:nth-of-type(2) {
  opacity: 0;
}

.navbar-toggler.active .toggler-icon:nth-of-type(3) {
  top: -7px;
  transform: rotate(135deg);
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .navbar-collapse {
    z-index: 9;
    background-color: #fff;
    width: 100%;
    padding: 5px 12px;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 15px 20px #0000001a;
  }
}

@media (width <= 767px) {
  .navbar-collapse {
    z-index: 9;
    background-color: #fff;
    width: 100%;
    padding: 5px 12px;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 15px 20px #0000001a;
  }
}

.navbar-nav .nav-item {
  margin-left: 40px;
  position: relative;
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .navbar-nav .nav-item {
    margin-left: 30px;
  }
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .navbar-nav .nav-item {
    margin: 0;
  }
}

@media (width <= 767px) {
  .navbar-nav .nav-item {
    margin: 0;
  }
}

.navbar-nav .nav-item a {
  color: #fff;
  -o-transition: all .3s ease-out 0s;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 400;
  transition: all .3s ease-out;
  position: relative;
}

.navbar-nav .nav-item a:before {
  content: "";
  -o-transition: all .3s ease-out 0s;
  background-color: #8b9474;
  width: 0;
  height: 3px;
  transition: all .3s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .navbar-nav .nav-item a:before {
    display: none;
  }
}

@media (width <= 767px) {
  .navbar-nav .nav-item a:before {
    display: none;
  }
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .navbar-nav .nav-item a {
    color: #222;
    padding: 4px 0;
    display: block;
  }
}

@media (width <= 767px) {
  .navbar-nav .nav-item a {
    color: #222;
    padding: 4px 0;
    display: block;
  }
}

.navbar-nav .nav-item.active > a:before, .navbar-nav .nav-item:hover > a:before {
  width: 100%;
}

.navbar-nav .nav-item:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .navbar-nav .nav-item:hover .sub-menu {
    top: 0;
  }
}

@media (width <= 767px) {
  .navbar-nav .nav-item:hover .sub-menu {
    top: 0;
  }
}

.navbar-nav .nav-item .sub-menu {
  opacity: 0;
  visibility: hidden;
  -o-transition: all .3s ease-out 0s;
  background-color: #fff;
  width: 200px;
  transition: all .3s ease-out;
  position: absolute;
  top: 110%;
  left: 0;
  box-shadow: 0 0 20px #0000001a;
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .navbar-nav .nav-item .sub-menu {
    opacity: 1;
    visibility: visible;
    width: 100%;
    display: none;
    position: relative;
    top: 0;
  }
}

@media (width <= 767px) {
  .navbar-nav .nav-item .sub-menu {
    opacity: 1;
    visibility: visible;
    width: 100%;
    display: none;
    position: relative;
    top: 0;
  }
}

.navbar-nav .nav-item .sub-menu li {
  display: block;
}

.navbar-nav .nav-item .sub-menu li a {
  color: #222;
  padding: 8px 20px;
  display: block;
}

.navbar-nav .nav-item .sub-menu li a.active, .navbar-nav .nav-item .sub-menu li a:hover {
  color: #8b9474;
  padding-left: 25px;
}

.navbar-nav .sub-nav-toggler {
  display: none;
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .navbar-nav .sub-nav-toggler {
    color: #222;
    background: none;
    border: 0;
    width: 30px;
    height: 30px;
    font-size: 18px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (width <= 767px) {
  .navbar-nav .sub-nav-toggler {
    color: #222;
    background: none;
    border: 0;
    width: 30px;
    height: 30px;
    font-size: 18px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.navbar-nav .sub-nav-toggler span {
  border-bottom: 1px solid #222;
  border-left: 1px solid #222;
  width: 8px;
  height: 8px;
  position: relative;
  top: -5px;
  transform: rotate(-45deg);
}

.sticky .navbar-toggler .toggler-icon {
  background-color: #222;
}

.sticky .navbar-nav .nav-item a {
  color: #222;
}

.sticky .navbar-nav .nav-item.active > a, .sticky .navbar-nav .nav-item:hover > a {
  color: #8b9474;
}

.single_slider {
  border-bottom-left-radius: 200px;
  height: 750px;
  position: relative;
  overflow: hidden;
}

@media only screen and (width >= 1400px) {
  .single_slider {
    height: 1120px;
  }
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .single_slider {
    border-bottom-left-radius: 100px;
    height: 700px;
  }
}

@media (width <= 767px) {
  .single_slider {
    border-bottom-left-radius: 80px;
    height: 650px;
  }
}

.single_slider:before {
  content: "";
  background-color: #1a315485;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.slider_content {
  padding-top: 50px;
}

.slider_content .slider_title {
  color: #fff;
  margin-top: 15px;
  font-size: 60px;
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .slider_content .slider_title {
    font-size: 44px;
  }
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .slider_content .slider_title {
    font-size: 40px;
  }
}

@media (width <= 767px) {
  .slider_content .slider_title {
    font-size: 30px;
  }
}

@media only screen and (width >= 576px) and (width <= 767px) {
  .slider_content .slider_title {
    font-size: 40px;
  }
}

.slider_content .slider_title span {
  color: #8b9474;
  display: contents;
}

.slider_content p {
  color: #fff;
  max-width: 550px;
  margin-top: 30px;
  font-size: 16px;
}

@media (width <= 767px) {
  .slider_content p {
    font-size: 16px;
  }
}

.slider_content .main-btn {
  margin-top: 45px;
}

.single_features .features_image img {
  border-radius: 40px;
  width: 100%;
}

.single_features .features_content .features_title a {
  color: #9f8768;
  margin-top: 30px;
  font-size: 30px;
  font-weight: 600;
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .single_features .features_content .features_title a {
    font-size: 24px;
  }
}

.about_area {
  z-index: 5;
  position: relative;
}

.about_bg {
  z-index: -1;
  opacity: .2;
  width: 50%;
  height: 100%;
  padding-right: 280px;
  position: absolute;
  top: 0;
  left: 0;
}

.about_bg .about_bg_image {
  width: 100%;
  height: 100%;
}

.about_image img {
  border-radius: 40px 40px 0;
  width: 100%;
}

.about_content {
  padding-left: 45px;
}

.about_content .sub_title {
  font-size: 38px;
  font-weight: 400;
  position: relative;
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .about_content .sub_title {
    font-size: 32px;
  }
}

@media (width <= 767px) {
  .about_content .sub_title {
    font-size: 24px;
  }
}

.about_content .sub_title:before {
  content: "";
  background-color: #9f8768;
  width: 9px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -45px;
}

.about_content .sub_title span {
  color: #8b9474;
  font-size: 70px;
  font-weight: 800;
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .about_content .sub_title span {
    font-size: 60px;
  }
}

@media (width <= 767px) {
  .about_content .sub_title span {
    font-size: 44px;
  }
}

.about_content .main_title {
  margin-top: 25px;
  font-size: 40px;
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .about_content .main_title {
    font-size: 32px;
  }
}

@media (width <= 767px) {
  .about_content .main_title {
    font-size: 24px;
  }
}

.about_content p {
  margin-top: 25px;
}

.about_content .main-btn {
  margin-top: 45px;
}

.single_services .services_icon i {
  color: #8b9474;
  text-align: center;
  background-color: #fff;
  border-radius: 10px 10px 0;
  width: 65px;
  height: 65px;
  font-size: 40px;
  line-height: 65px;
  box-shadow: 0 3px 10px #d5d5d566;
}

.single_services .services_content {
  margin-top: 25px;
}

.single_services .services_content .title {
  object-fit: cover;
  object-position: center;
  font-size: 30px;
  font-weight: 700;
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .single_services .services_content .title {
    font-size: 24px;
  }
}

@media (width <= 767px) {
  .single_services .services_content .title {
    font-size: 24px;
  }
}

.single_services .services_content p {
  margin-top: 25px;
}

.single_galley {
  position: relative;
  overflow: hidden;
}

.single_galley .galley_image {
  position: relative;
}

.single_galley .galley_image img {
  width: 100%;
}

.single_galley .galley_image:before {
  content: "";
  -o-transition: all .3s ease-out 0s;
  opacity: 0;
  visibility: hidden;
  background-color: #1a3154ba;
  width: 100%;
  height: 100%;
  transition: all .3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

.single_galley .galley_content {
  opacity: 1;
  visibility: hidden;
  -o-transition: all .3s ease-out 0s;
  width: 100%;
  padding: 30px;
  transition: all .3s ease-out;
  position: absolute;
  bottom: 30px;
  left: 30px;
}

@media (width <= 767px) {
  .single_galley .galley_content {
    bottom: 15px;
    left: 15px;
  }
}

.single_galley .galley_content:before {
  content: "";
  -o-transition: all .3s ease-out 0s;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  width: 0;
  height: 0;
  transition: all .3s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
}

.single_galley .galley_content .gallery_title {
  overflow: hidden;
}

.single_galley .galley_content .gallery_title a {
  color: #fff;
  -o-transition: all .3s ease-out 0s;
  font-size: 24px;
  font-weight: 500;
  transition: all .3s ease-out;
  transform: translateX(-100%);
}

@media (width <= 767px) {
  .single_galley .galley_content .gallery_title a {
    font-size: 20px;
  }
}

.single_galley .galley_content p {
  color: #fff;
  -o-transition: all .3s ease-out 0s;
  margin-top: 5px;
  transition: all .3s ease-out;
  overflow: hidden;
}

.single_galley .galley_content p span {
  -o-transition: all .3s ease-out 0s;
  transition: all .3s ease-out;
  transform: translateX(-100%);
}

.single_galley:hover .galley_image:before, .single_galley:hover .galley_content {
  opacity: 1;
  visibility: visible;
}

.single_galley:hover .galley_content:before {
  -o-transition-delay: .2s;
  width: 110px;
  height: 110px;
  transition-delay: .2s;
}

.single_galley:hover .galley_content .gallery_title a {
  -o-transition-delay: .4s;
  transition-delay: .4s;
  transform: translateX(0);
}

.single_galley:hover .galley_content p span {
  -o-transition-delay: .6s;
  transition-delay: .6s;
  transform: translateX(0);
}

.single_galley.border_radius_1 {
  border-radius: 40px 40px 0;
}

@media (width <= 767px) {
  .single_galley.border_radius_1 {
    border-radius: 20px 20px 0;
  }
}

.single_galley.border_radius_2 {
  border-radius: 40px 0 40px 40px;
}

@media (width <= 767px) {
  .single_galley.border_radius_2 {
    border-radius: 20px 0 20px 20px;
  }
}

.single_galley.border_radius_3 {
  border-radius: 0 40px 40px;
}

@media (width <= 767px) {
  .single_galley.border_radius_3 {
    border-radius: 0 20px 20px;
  }
}

.single_galley.border_radius_4 {
  border-radius: 40px 40px 40px 0;
}

@media (width <= 767px) {
  .single_galley.border_radius_4 {
    border-radius: 20px 20px 20px 0;
  }
}

.counter_area {
  z-index: 5;
  border-top-left-radius: 150px;
  border-bottom-right-radius: 150px;
  position: relative;
  overflow: hidden;
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .counter_area {
    border-top-left-radius: 80px;
    border-bottom-right-radius: 80px;
  }
}

@media (width <= 767px) {
  .counter_area {
    border-top-left-radius: 60px;
    border-bottom-right-radius: 60px;
  }
}

.counter_area:before {
  content: "";
  background-color: #1a3154c9;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.single_counter .counter_icon i {
  color: #fff;
  font-size: 60px;
  line-height: 60px;
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .single_counter .counter_icon i {
    font-size: 44px;
    line-height: 50px;
  }
}

@media (width <= 767px) {
  .single_counter .counter_icon i {
    font-size: 44px;
    line-height: 50px;
  }
}

@media only screen and (width >= 576px) and (width <= 767px) {
  .single_counter .counter_icon i {
    font-size: 40px;
    line-height: 50px;
  }
}

.single_counter .counter_content {
  margin-top: 25px;
}

.single_counter .counter_content span {
  color: #fff;
  font-size: 44px;
  font-weight: 700;
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .single_counter .counter_content span {
    font-size: 38px;
  }
}

@media (width <= 767px) {
  .single_counter .counter_content span {
    font-size: 38px;
  }
}

@media only screen and (width >= 576px) and (width <= 767px) {
  .single_counter .counter_content span {
    font-size: 34px;
  }
}

.single_counter .counter_content p {
  color: #fff;
}

@media only screen and (width <= 768px) {
  .single_team .team_image img {
    width: 50% !important;
  }

  .single_team .team_image .social li {
    box-shadow: 0 1px 4px #00000029;
    opacity: 1 !important;
    border-radius: inherit !important;
    background-color: #fff !important;
    transform: translateX(0) !important;
  }

  .single_team .team_image .social {
    left: 20px !important;
  }
}

.single_team .team_image {
  border-radius: 40px 40px 0;
  position: relative;
  overflow: hidden;
}

.single_team .team_image img {
  width: 100%;
}

.single_team .team_image .social {
  position: absolute;
  top: 70%;
  left: 0;
  transform: translateY(-50%);
}

.single_team .team_image .social li {
  -o-transition: all .3s ease-out 0s;
  opacity: 0;
  border-radius: 0 40px 0 0;
  width: auto;
  margin-top: 70px;
  padding: 4px 20px 4px 4px;
  line-height: 42px;
  transition: all .3s ease-out;
  transform: translateX(-100%);
}

.single_team .team_image .social li a {
  text-align: center;
  color: #677284;
  -o-transition: all .3s ease-out 0s;
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 42px;
  transition: all .3s ease-out;
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .single_team .team_image .social li a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

.single_team .team_image .social li a:hover {
  color: #8b9474;
}

.single_team .team_content {
  margin-top: 25px;
}

.single_team .team_content .team_name a {
  color: #9f8768;
  -o-transition: all .3s ease-out 0s;
  font-size: 20px;
  font-weight: 600;
  transition: all .3s ease-out;
}

.single_team .team_content .team_name a:hover {
  color: #8b9474;
}

.single_team .team_content p {
  margin-top: 10px;
}

.single_team:hover .team_image .social li {
  opacity: 1;
  visibility: visible;
  background: #fff;
  margin-top: 70px;
  transform: translateX(0);
}

.single_team:hover .team_image .social li:first-of-type {
  -o-transition-delay: .2s;
  transition-delay: .2s;
}

.single_team:hover .team_image .social li:nth-of-type(2) {
  -o-transition-delay: .3s;
  transition-delay: .3s;
}

.single_team:hover .team_image .social li:nth-of-type(3) {
  -o-transition-delay: .4s;
  transition-delay: .4s;
}

.single_team:hover .team_image .social li:nth-of-type(4) {
  -o-transition-delay: .5s;
  transition-delay: .5s;
}

.testimonial_area {
  z-index: 5;
  border-top-left-radius: 150px;
  border-bottom-right-radius: 150px;
  position: relative;
  overflow: hidden;
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .testimonial_area {
    border-top-left-radius: 80px;
    border-bottom-right-radius: 80px;
  }
}

@media (width <= 767px) {
  .testimonial_area {
    border-top-left-radius: 60px;
    border-bottom-right-radius: 60px;
  }
}

.testimonial_area:before {
  content: "";
  background-color: #1a3154c9;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.testimonial_author {
  width: 171px;
  margin: 0 auto;
}

.testimonial_author .single_author img {
  -o-transition: all .3s ease-out 0s;
  border-radius: 50%;
  transition: all .3s ease-out;
  display: inline-block;
}

.testimonial_author .slick-center.single_author img {
  border: 3px solid #fff;
}

.testimonial_content .single_content {
  margin-top: 30px;
}

.testimonial_content .single_content p {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.testimonial_content .single_content .author_name {
  color: #fff;
  margin-top: 35px;
  font-size: 20px;
  font-weight: 600;
}

.testimonial_content .single_content span {
  color: #fff;
  margin-top: 10px;
  font-size: 16px;
}

.testimonial_content .slick-arrow {
  z-index: 5;
  position: absolute;
  top: 30%;
  left: -130px;
  transform: translateY(-50%);
}

@media (width <= 767px) {
  .testimonial_content .slick-arrow {
    left: -30px;
  }
}

.testimonial_content .slick-arrow i {
  text-align: center;
  color: #fff;
  cursor: pointer;
  -o-transition: all .3s ease-out 0s;
  background-color: #677284;
  border-radius: 5px 5px 0;
  width: 45px;
  height: 45px;
  font-size: 24px;
  line-height: 45px;
  transition: all .3s ease-out;
}

@media (width <= 767px) {
  .testimonial_content .slick-arrow i {
    width: 35px;
    height: 35px;
    font-size: 18px;
    line-height: 35px;
  }
}

.testimonial_content .slick-arrow i:hover {
  color: #8b9474;
  background-color: #fff;
}

.testimonial_content .slick-arrow.next {
  left: auto;
  right: -130px;
}

@media (width <= 767px) {
  .testimonial_content .slick-arrow.next {
    left: auto;
    right: -30px;
  }
}

.testimonial_content .slick-arrow.next i {
  border-radius: 5px 5px 5px 0;
}

.single_logo img {
  border-radius: 50%;
  width: 100%;
  box-shadow: 0 3px 24px #d5d5d57d;
}

.single_logo.logo_2 {
  padding-top: 158px;
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .single_logo.logo_2 {
    padding-top: 130px;
  }
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .single_logo.logo_2 {
    padding-top: 90px;
  }
}

@media (width <= 767px) {
  .single_logo.logo_2 {
    padding-top: 0;
  }
}

p.form-message.success, p.form-message.error {
  color: #9f8768;
  background: #f7f7fd;
  margin-top: 30px;
  padding: 10px 15px;
  font-size: 16px;
}

p.form-message.success.form-message.error, p.form-message.error.form-message.error {
  color: red;
}

.contact_form .single_form {
  margin-top: 30px;
}

.contact_form .single_form textarea, .contact_form .single_form input {
  border: 1px solid #677284;
  border-radius: 5px;
  width: 100%;
  height: 55px;
  padding: 0 25px;
}

.contact_form .single_form textarea:focus, .contact_form .single_form input:focus {
  border: 1px solid #8b9474;
}

.contact_form .single_form textarea {
  resize: none;
  height: 125px;
  padding-top: 15px;
}

.contact_info p {
  margin-top: 15px;
}

.single_info {
  margin-top: 30px;
}

.single_info .info_icon i {
  text-align: center;
  color: #fff;
  background-color: #8b9474;
  border-radius: 5px;
  width: 45px;
  height: 45px;
  font-size: 20px;
  line-height: 45px;
}

@media (width <= 767px) {
  .single_info .info_icon i {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}

.single_info .info_content {
  padding-left: 15px;
}

.single_info .info_content p {
  margin-top: 0;
}

@media (width <= 767px) {
  .single_info .info_content p {
    font-size: 14px;
  }
}

@media only screen and (width >= 576px) and (width <= 767px) {
  .single_info .info_content p {
    font-size: 16px;
  }
}

.footer_area {
  z-index: 5;
  border-top-left-radius: 150px;
  position: relative;
  overflow: hidden;
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .footer_area {
    border-top-left-radius: 80px;
  }
}

@media (width <= 767px) {
  .footer_area {
    border-top-left-radius: 60px;
  }
}

.footer_area:before {
  content: "";
  z-index: -1;
  background-color: #8b9474;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.footer_about img {
  max-width: 50%;
}

.footer_about p {
  color: #fff;
  margin-top: 25px;
}

.footer_title {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}

.footer_link_wrapper .footer_link {
  width: 33.33%;
}

@media (width <= 767px) {
  .footer_link_wrapper .footer_link {
    width: 100%;
  }
}

@media only screen and (width >= 576px) and (width <= 767px) {
  .footer_link_wrapper .footer_link {
    width: 50%;
  }
}

.footer_link_wrapper .footer_link .link {
  padding-top: 25px;
}

.footer_link_wrapper .footer_link .link li {
  margin-top: 10px;
}

.footer_link_wrapper .footer_link .link li a {
  color: #fff;
  -o-transition: all .3s ease-out 0s;
  font-size: 16px;
  font-weight: 400;
  transition: all .3s ease-out;
}

.footer_link_wrapper .footer_link .link li a:hover {
  color: #8b9474;
}

.footer_copyright {
  border-top: 1px solid #ffffff94;
  padding: 25px 0;
}

.footer_copyright p {
  color: #fff;
}

.back-to-top {
  color: #fff;
  text-align: center;
  z-index: 99;
  -o-transition: all .3s ease-out 0s;
  background-color: #8b9474;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  transition: all .3s ease-out;
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.back-to-top:hover {
  color: #fff;
  background-color: #8b9474;
}

/*# sourceMappingURL=index.ff165935.css.map */
