/*-----------------------------------------------------------------------------------

    Template Name: Arch
    Author: UIdeck
    Author URI: uideck.com
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme default CSS
	02. Header
    03. Hero
	04. Footer

-----------------------------------------------------------------------------------*/
/*===========================
      01.COMMON css 
===========================*/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");
body {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #677284; }

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

img {
  max-width: 100%; }

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none; }

a:focus,
a:hover {
  text-decoration: none; }

i,
span,
a {
  display: inline-block; 
}

a, a:hover {
  color: #8b9474ff; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #9F8768;
  margin: 0px; }

h1 {
  font-size: 48px; }

h2 {
  font-size: 36px; }

h3 {
  font-size: 28px; }

h4 {
  font-size: 22px; }

h5 {
  font-size: 18px; }

h6 {
  font-size: 16px; }

ul, ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none; }

  p b {
    color: #8b9474;;
  }

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: black;
  margin: 0px; }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .bg_cover {
      background-size: contain;
      height: 67vw !important;
    }
  }

.bg_cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%; }

/*===== All Button Style =====*/
.main-btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0 40px;
  font-size: 16px;
  line-height: 48px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background-color: #8b9474ff; }
  .main-btn:hover {
    background-color: rgba(255, 179, 22, 0.8);
    color: #fff; }
  .main-btn.main-btn-2 {
    background-color: #fff;
    color: #8b9474ff;
    border-color: #8b9474ff; }
    .main-btn.main-btn-2:hover {
      background-color: #8b9474ff;
      border-color: #8b9474ff;
      color: #fff; }

/*===== All Button Style =====*/
.section_title .title {
  font-size: 40px;
  font-weight: 700; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section_title .title {
      font-size: 32px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section_title .title {
      font-size: 34px; } }
  @media (max-width: 767px) {
    .section_title .title {
      font-size: 24px; } }

.section_title.section_title_2 .title {
  color: #fff; }

/*===== All Slick Slide Outline Style =====*/
.slick-slide {
  outline: 0; }

/*===== All Preloader Style =====*/
.preloader {
  /* Body Overlay */
  position: fixed;
  top: 0;
  left: 0;
  display: table;
  height: 100%;
  width: 100%;
  /* Change Background Color */
  background: #fff;
  z-index: 99999; }
  .preloader .loader {
    display: table-cell;
    vertical-align: middle;
    text-align: center; }
    .preloader .loader .ytp-spinner {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 64px;
      margin-left: -32px;
      z-index: 18;
      pointer-events: none; }
      .preloader .loader .ytp-spinner .ytp-spinner-container {
        pointer-events: none;
        position: absolute;
        width: 100%;
        padding-bottom: 100%;
        top: 50%;
        left: 50%;
        margin-top: -50%;
        margin-left: -50%;
        -webkit-animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
        -moz-animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
        -o-animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
        animation: ytp-spinner-linspin 1568.23529647ms linear infinite; }
        .preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator {
          position: absolute;
          width: 100%;
          height: 100%;
          -webkit-animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
          -moz-animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
          -o-animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
          animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }
          .preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator .ytp-spinner-left {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            overflow: hidden;
            right: 50%; }
          .preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator .ytp-spinner-right {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
            left: 50%; }
    .preloader .loader .ytp-spinner-circle {
      box-sizing: border-box;
      position: absolute;
      width: 200%;
      height: 100%;
      border-style: solid;
      /* Spinner Color */
      border-color: #8b9474ff #8b9474ff #f7f7fd;
      border-radius: 50%;
      border-width: 6px; }
    .preloader .loader .ytp-spinner-left .ytp-spinner-circle {
      left: 0;
      right: -100%;
      border-right-color: #f7f7fd;
      -webkit-animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      -moz-animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      -o-animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }
    .preloader .loader .ytp-spinner-right .ytp-spinner-circle {
      left: -100%;
      right: 0;
      border-left-color: #f7f7fd;
      -webkit-animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      -moz-animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      -o-animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

/* Preloader Animations */
@-webkit-keyframes ytp-spinner-linspin {
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes ytp-spinner-linspin {
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes ytp-spinner-easespin {
  12.5% {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg); }
  25% {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg); }
  37.5% {
    -webkit-transform: rotate(405deg);
    -moz-transform: rotate(405deg);
    -ms-transform: rotate(405deg);
    -o-transform: rotate(405deg);
    transform: rotate(405deg); }
  50% {
    -webkit-transform: rotate(540deg);
    -moz-transform: rotate(540deg);
    -ms-transform: rotate(540deg);
    -o-transform: rotate(540deg);
    transform: rotate(540deg); }
  62.5% {
    -webkit-transform: rotate(675deg);
    -moz-transform: rotate(675deg);
    -ms-transform: rotate(675deg);
    -o-transform: rotate(675deg);
    transform: rotate(675deg); }
  75% {
    -webkit-transform: rotate(810deg);
    -moz-transform: rotate(810deg);
    -ms-transform: rotate(810deg);
    -o-transform: rotate(810deg);
    transform: rotate(810deg); }
  87.5% {
    -webkit-transform: rotate(945deg);
    -moz-transform: rotate(945deg);
    -ms-transform: rotate(945deg);
    -o-transform: rotate(945deg);
    transform: rotate(945deg); }
  to {
    -webkit-transform: rotate(1080deg);
    -moz-transform: rotate(1080deg);
    -ms-transform: rotate(1080deg);
    -o-transform: rotate(1080deg);
    transform: rotate(1080deg); } }

@keyframes ytp-spinner-easespin {
  12.5% {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg); }
  25% {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg); }
  37.5% {
    -webkit-transform: rotate(405deg);
    -moz-transform: rotate(405deg);
    -ms-transform: rotate(405deg);
    -o-transform: rotate(405deg);
    transform: rotate(405deg); }
  50% {
    -webkit-transform: rotate(540deg);
    -moz-transform: rotate(540deg);
    -ms-transform: rotate(540deg);
    -o-transform: rotate(540deg);
    transform: rotate(540deg); }
  62.5% {
    -webkit-transform: rotate(675deg);
    -moz-transform: rotate(675deg);
    -ms-transform: rotate(675deg);
    -o-transform: rotate(675deg);
    transform: rotate(675deg); }
  75% {
    -webkit-transform: rotate(810deg);
    -moz-transform: rotate(810deg);
    -ms-transform: rotate(810deg);
    -o-transform: rotate(810deg);
    transform: rotate(810deg); }
  87.5% {
    -webkit-transform: rotate(945deg);
    -moz-transform: rotate(945deg);
    -ms-transform: rotate(945deg);
    -o-transform: rotate(945deg);
    transform: rotate(945deg); }
  to {
    -webkit-transform: rotate(1080deg);
    -moz-transform: rotate(1080deg);
    -ms-transform: rotate(1080deg);
    -o-transform: rotate(1080deg);
    transform: rotate(1080deg); } }

@-webkit-keyframes ytp-spinner-left-spin {
  0% {
    -webkit-transform: rotate(130deg);
    -moz-transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    -o-transform: rotate(130deg);
    transform: rotate(130deg); }
  50% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  to {
    -webkit-transform: rotate(130deg);
    -moz-transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    -o-transform: rotate(130deg);
    transform: rotate(130deg); } }

@keyframes ytp-spinner-left-spin {
  0% {
    -webkit-transform: rotate(130deg);
    -moz-transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    -o-transform: rotate(130deg);
    transform: rotate(130deg); }
  50% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  to {
    -webkit-transform: rotate(130deg);
    -moz-transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    -o-transform: rotate(130deg);
    transform: rotate(130deg); } }

@-webkit-keyframes ytp-right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg); }
  50% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg); }
  to {
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg); } }

@keyframes ytp-right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg); }
  50% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg); }
  to {
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg); } }

/*===========================
     02.HEADER css 
===========================*/
/*===== NAVBAR =====*/
.header_navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s; }

.sticky {
  position: fixed;
  z-index: 99;
  background-color: #fff;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s; }
  .sticky .navbar {
    padding: 15px 0; }

.navbar {
  padding: 20px 0;
  border-radius: 5px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s; }

.navbar-brand {
  padding: 0; }
  .navbar-brand img {
    width: 150px; }

.navbar-toggler {
  padding: 0; }
  .navbar-toggler .toggler-icon {
    width: 30px;
    height: 2px;
    background-color: #fff;
    display: block;
    margin: 5px 0;
    position: relative;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s; }
  .navbar-toggler.active .toggler-icon:nth-of-type(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 7px; }
  .navbar-toggler.active .toggler-icon:nth-of-type(2) {
    opacity: 0; }
  .navbar-toggler.active .toggler-icon:nth-of-type(3) {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    top: -7px; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 5px 12px; } }

@media (max-width: 767px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 5px 12px; } }

.navbar-nav .nav-item {
  position: relative;
  margin-left: 40px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .navbar-nav .nav-item {
      margin-left: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .nav-item {
      margin: 0; } }
  @media (max-width: 767px) {
    .navbar-nav .nav-item {
      margin: 0; } }
  .navbar-nav .nav-item a {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    padding: 10px 0;
    position: relative; }
    .navbar-nav .nav-item a::before {
      position: absolute;
      content: '';
      bottom: 0px;
      left: 0;
      width: 0;
      height: 3px;
      background-color: #8b9474ff;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .navbar-nav .nav-item a::before {
          display: none; } }
      @media (max-width: 767px) {
        .navbar-nav .nav-item a::before {
          display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .navbar-nav .nav-item a {
        display: block;
        padding: 4px 0;
        color: #222; } }
    @media (max-width: 767px) {
      .navbar-nav .nav-item a {
        display: block;
        padding: 4px 0;
        color: #222; } }
  .navbar-nav .nav-item.active > a::before, .navbar-nav .nav-item:hover > a::before {
    width: 100%; }
  .navbar-nav .nav-item:hover .sub-menu {
    top: 100%;
    opacity: 1;
    visibility: visible; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .navbar-nav .nav-item:hover .sub-menu {
        top: 0; } }
    @media (max-width: 767px) {
      .navbar-nav .nav-item:hover .sub-menu {
        top: 0; } }
  .navbar-nav .nav-item .sub-menu {
    width: 200px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 110%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .navbar-nav .nav-item .sub-menu {
        position: relative;
        width: 100%;
        top: 0;
        display: none;
        opacity: 1;
        visibility: visible; } }
    @media (max-width: 767px) {
      .navbar-nav .nav-item .sub-menu {
        position: relative;
        width: 100%;
        top: 0;
        display: none;
        opacity: 1;
        visibility: visible; } }
    .navbar-nav .nav-item .sub-menu li {
      display: block; }
      .navbar-nav .nav-item .sub-menu li a {
        display: block;
        padding: 8px 20px;
        color: #222; }
        .navbar-nav .nav-item .sub-menu li a.active, .navbar-nav .nav-item .sub-menu li a:hover {
          padding-left: 25px;
          color: #8b9474ff; }

.navbar-nav .sub-nav-toggler {
  display: none; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .sub-nav-toggler {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      background: none;
      color: #222;
      font-size: 18px;
      border: 0;
      width: 30px;
      height: 30px; } }
  @media (max-width: 767px) {
    .navbar-nav .sub-nav-toggler {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      background: none;
      color: #222;
      font-size: 18px;
      border: 0;
      width: 30px;
      height: 30px; } }
  .navbar-nav .sub-nav-toggler span {
    width: 8px;
    height: 8px;
    border-left: 1px solid #222;
    border-bottom: 1px solid #222;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: relative;
    top: -5px; }

.sticky .navbar-toggler .toggler-icon {
  background-color: #222; }

.sticky .navbar-nav .nav-item a {
  color: #222; }

.sticky .navbar-nav .nav-item.active > a, .sticky .navbar-nav .nav-item:hover > a {
  color: #8b9474ff; }

/*===== SLIDER =====*/
.single_slider {
  height: 750px;
  position: relative;
  overflow: hidden;
  border-bottom-left-radius: 200px; }
  @media only screen and (min-width: 1400px) {
    .single_slider {
      height: 1120px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_slider {
      height: 700px;
      border-bottom-left-radius: 100px; } }
  @media (max-width: 767px) {
    .single_slider {
      height: 650px;
      border-bottom-left-radius: 80px; } }
  .single_slider::before {
    position: absolute;
    content: '';
    background-color: rgba(26, 49, 84, 0.52);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }

.slider_content {
  padding-top: 50px; }
  .slider_content .slider_title {
    font-size: 60px;
    color: #fff;
    margin-top: 15px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slider_content .slider_title {
        font-size: 44px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider_content .slider_title {
        font-size: 40px; } }
    @media (max-width: 767px) {
      .slider_content .slider_title {
        font-size: 30px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .slider_content .slider_title {
        font-size: 40px; } }
    .slider_content .slider_title span {
      display: contents;
      color: #8b9474ff; }
  .slider_content p {
    font-size: 16px;
    color: #fff;
    margin-top: 30px;
    max-width: 550px; }
    @media (max-width: 767px) {
      .slider_content p {
        font-size: 16px; } }
  .slider_content .main-btn {
    margin-top: 45px; }

/*===========================
      03.FEATUREAS css 
===========================*/
.single_features .features_image img {
  width: 100%;
  border-radius: 40px; }

.single_features .features_content .features_title a {
  font-size: 30px;
  font-weight: 600;
  color: #9F8768;
  margin-top: 30px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_features .features_content .features_title a {
      font-size: 24px; } }

/*===========================
       04.ABOUT css 
===========================*/
.about_area {
  position: relative;
  z-index: 5; }

.about_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  opacity: 0.2;
  padding-right: 280px; }
  .about_bg .about_bg_image {
    width: 100%;
    height: 100%; }

.about_image img {
  width: 100%;
  border-radius: 40px;
  border-bottom-right-radius: 0; }

.about_content {
  padding-left: 45px; }
  .about_content .sub_title {
    font-size: 38px;
    font-weight: 400;
    position: relative; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .about_content .sub_title {
        font-size: 32px; } }
    @media (max-width: 767px) {
      .about_content .sub_title {
        font-size: 24px; } }
    .about_content .sub_title::before {
      position: absolute;
      content: '';
      width: 9px;
      height: 100%;
      left: -45px;
      top: 0;
      background-color: #9F8768; }
    .about_content .sub_title span {
      font-size: 70px;
      font-weight: 800;
      color: #8b9474ff; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .about_content .sub_title span {
          font-size: 60px; } }
      @media (max-width: 767px) {
        .about_content .sub_title span {
          font-size: 44px; } }
  .about_content .main_title {
    font-size: 40px;
    margin-top: 25px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .about_content .main_title {
        font-size: 32px; } }
    @media (max-width: 767px) {
      .about_content .main_title {
        font-size: 24px; } }
  .about_content p {
    margin-top: 25px; }
  .about_content .main-btn {
    margin-top: 45px; }

/*===========================
       05.SERVICES css 
===========================*/
.single_services .services_icon i {
  width: 65px;
  height: 65px;
  line-height: 65px;
  font-size: 40px;
  color: #8b9474ff;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(213, 213, 213, 0.4);
  -moz-box-shadow: 0px 3px 10px 0px rgba(213, 213, 213, 0.4);
  box-shadow: 0px 3px 10px 0px rgba(213, 213, 213, 0.4); }

.single_services .services_content {
  margin-top: 25px; }
  .single_services .services_content .title {
    font-size: 30px;
    font-weight: 700;
    object-fit: cover;
    object-position: center; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single_services .services_content .title {
        font-size: 24px; } }
    @media (max-width: 767px) {
      .single_services .services_content .title {
        font-size: 24px; } }
  .single_services .services_content p {
    margin-top: 25px; }

/*===========================
       06.GALLERY css 
===========================*/
.single_galley {
  position: relative;
  overflow: hidden; }
  .single_galley .galley_image {
    position: relative; }
    .single_galley .galley_image img {
      width: 100%; }
    .single_galley .galley_image::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      background-color: rgba(26, 49, 84, 0.73);
      width: 100%;
      height: 100%;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s;
      opacity: 0;
      visibility: hidden; }
  .single_galley .galley_content {
    position: absolute;
    bottom: 30px;
    left: 30px;
    width: 100%;
    padding: 30px;
    opacity: 1;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s; }
    @media (max-width: 767px) {
      .single_galley .galley_content {
        left: 15px;
        bottom: 15px; } }
    .single_galley .galley_content::before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      left: 0;
      bottom: 0;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s; }
    .single_galley .galley_content .gallery_title {
      overflow: hidden; }
      .single_galley .galley_content .gallery_title a {
        font-size: 24px;
        color: #fff;
        font-weight: 500;
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s;
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%); }
        @media (max-width: 767px) {
          .single_galley .galley_content .gallery_title a {
            font-size: 20px; } }
    .single_galley .galley_content p {
      color: #fff;
      margin-top: 5px;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s;
      overflow: hidden; }
      .single_galley .galley_content p span {
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s; }
  .single_galley:hover .galley_image::before {
    opacity: 1;
    visibility: visible; }
  .single_galley:hover .galley_content {
    opacity: 1;
    visibility: visible; }
    .single_galley:hover .galley_content::before {
      width: 110px;
      height: 110px;
      -webkit-transition-delay: 0.2s;
      -moz-transition-delay: 0.2s;
      -ms-transition-delay: 0.2s;
      -o-transition-delay: 0.2s;
      transition-delay: 0.2s; }
    .single_galley:hover .galley_content .gallery_title a {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
      -webkit-transition-delay: 0.4s;
      -moz-transition-delay: 0.4s;
      -ms-transition-delay: 0.4s;
      -o-transition-delay: 0.4s;
      transition-delay: 0.4s; }
    .single_galley:hover .galley_content p span {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
      -webkit-transition-delay: 0.6s;
      -moz-transition-delay: 0.6s;
      -ms-transition-delay: 0.6s;
      -o-transition-delay: 0.6s;
      transition-delay: 0.6s; }
  .single_galley.border_radius_1 {
    border-radius: 40px;
    border-bottom-right-radius: 0; }
    @media (max-width: 767px) {
      .single_galley.border_radius_1 {
        border-radius: 20px;
        border-bottom-right-radius: 0; } }
  .single_galley.border_radius_2 {
    border-radius: 40px;
    border-top-right-radius: 0; }
    @media (max-width: 767px) {
      .single_galley.border_radius_2 {
        border-radius: 20px;
        border-top-right-radius: 0; } }
  .single_galley.border_radius_3 {
    border-radius: 40px;
    border-top-left-radius: 0; }
    @media (max-width: 767px) {
      .single_galley.border_radius_3 {
        border-radius: 20px;
        border-top-left-radius: 0; } }
  .single_galley.border_radius_4 {
    border-radius: 40px;
    border-bottom-left-radius: 0; }
    @media (max-width: 767px) {
      .single_galley.border_radius_4 {
        border-radius: 20px;
        border-bottom-left-radius: 0; } }

/*===========================
       07.COUNTER css 
===========================*/
.counter_area {
  position: relative;
  z-index: 5;
  overflow: hidden;
  border-top-left-radius: 150px;
  border-bottom-right-radius: 150px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .counter_area {
      border-top-left-radius: 80px;
      border-bottom-right-radius: 80px; } }
  @media (max-width: 767px) {
    .counter_area {
      border-top-left-radius: 60px;
      border-bottom-right-radius: 60px; } }
  .counter_area::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    background-color: rgba(26, 49, 84, 0.79);
    width: 100%;
    height: 100%; }

.single_counter .counter_icon i {
  font-size: 60px;
  line-height: 60px;
  color: #fff; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_counter .counter_icon i {
      font-size: 44px;
      line-height: 50px; } }
  @media (max-width: 767px) {
    .single_counter .counter_icon i {
      font-size: 44px;
      line-height: 50px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .single_counter .counter_icon i {
      font-size: 40px;
      line-height: 50px; } }

.single_counter .counter_content {
  margin-top: 25px; }
  .single_counter .counter_content span {
    font-size: 44px;
    font-weight: 700;
    color: #fff; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single_counter .counter_content span {
        font-size: 38px; } }
    @media (max-width: 767px) {
      .single_counter .counter_content span {
        font-size: 38px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .single_counter .counter_content span {
        font-size: 34px; } }
  .single_counter .counter_content p {
    color: #fff; }

/*===========================
       08.TEAM css 
===========================*/
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .single_team .team_image img{
      width: 50% !important;
  }

  .single_team .team_image .social li{
    transform: translateX(0) !important;
    background-color: white !important;
    opacity: 1 !important;
    border-radius: inherit !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  .single_team .team_image .social{
    left: 20px !important;
  }
}

.single_team .team_image {
  position: relative;
  overflow: hidden;
  border-radius: 40px;
  border-bottom-right-radius: 0; }
  .single_team .team_image img {
    width: 100%; }
  .single_team .team_image .social {
    position: absolute;
    top: 70%;
    left: 0px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
    .single_team .team_image .social li {
      margin-top: 70px;
      padding: 4px;
      padding-right: 20px;
      width: auto;
      border-radius: 0px 40px 0px 0px;
      line-height: 42px;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s;
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      transform: translateX(-100%);
      opacity: 0; }
      .single_team .team_image .social li a {
        width: 40px;
        height: 40px;
        line-height: 42px;
        text-align: center;
        border-radius: 50%;
        background-color: #fff;
        font-size: 16px;
        color: #677284;
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .single_team .team_image .social li a {
            width: 30px;
            height: 30px;
            line-height: 30px; } }
        .single_team .team_image .social li a:hover {
          color: #8b9474ff; }

.single_team .team_content {
  margin-top: 25px; }
  .single_team .team_content .team_name a {
    font-size: 20px;
    font-weight: 600;
    color: #9F8768;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s; }
    .single_team .team_content .team_name a:hover {
      color: #8b9474ff; }
  .single_team .team_content p {
    margin-top: 10px; }

.single_team:hover .team_image .social li {
    background: white; 
    margin-top: 70px;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible; }
  .single_team:hover .team_image .social li:nth-of-type(1) {
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay: 0.2s;
    -ms-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s; }
  .single_team:hover .team_image .social li:nth-of-type(2) {
    -webkit-transition-delay: 0.3s;
    -moz-transition-delay: 0.3s;
    -ms-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s; }
  .single_team:hover .team_image .social li:nth-of-type(3) {
    -webkit-transition-delay: 0.4s;
    -moz-transition-delay: 0.4s;
    -ms-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    transition-delay: 0.4s; }
  .single_team:hover .team_image .social li:nth-of-type(4) {
    -webkit-transition-delay: 0.5s;
    -moz-transition-delay: 0.5s;
    -ms-transition-delay: 0.5s;
    -o-transition-delay: 0.5s;
    transition-delay: 0.5s; }

/*===========================
     09.TESTIMONIAL css 
===========================*/
.testimonial_area {
  position: relative;
  z-index: 5;
  overflow: hidden;
  border-top-left-radius: 150px;
  border-bottom-right-radius: 150px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial_area {
      border-top-left-radius: 80px;
      border-bottom-right-radius: 80px; } }
  @media (max-width: 767px) {
    .testimonial_area {
      border-top-left-radius: 60px;
      border-bottom-right-radius: 60px; } }
  .testimonial_area::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    background-color: rgba(26, 49, 84, 0.79);
    width: 100%;
    height: 100%; }

.testimonial_author {
  width: 171px;
  margin: 0 auto; }
  .testimonial_author .single_author img {
    border-radius: 50%;
    display: inline-block;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s; }
  .testimonial_author .slick-center.single_author img {
    border: 3px solid #fff; }

.testimonial_content .single_content {
  margin-top: 30px; }
  .testimonial_content .single_content p {
    color: #fff;
    font-size: 18px;
    font-weight: 500; }
  .testimonial_content .single_content .author_name {
    font-size: 20px;
    font-weight: 200;
    font-weight: 600;
    color: #fff;
    margin-top: 35px; }
  .testimonial_content .single_content span {
    font-size: 16px;
    color: #fff;
    margin-top: 10px; }

.testimonial_content .slick-arrow {
  position: absolute;
  top: 30%;
  left: -130px;
  z-index: 5;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }
  @media (max-width: 767px) {
    .testimonial_content .slick-arrow {
      left: -30px; } }
  .testimonial_content .slick-arrow i {
    background-color: #677284;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 24px;
    color: #fff;
    border-radius: 5px;
    border-bottom-right-radius: 0;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s; }
    @media (max-width: 767px) {
      .testimonial_content .slick-arrow i {
        width: 35px;
        height: 35px;
        line-height: 35px;
        font-size: 18px; } }
    .testimonial_content .slick-arrow i:hover {
      background-color: #fff;
      color: #8b9474ff; }
  .testimonial_content .slick-arrow.next {
    left: auto;
    right: -130px; }
    @media (max-width: 767px) {
      .testimonial_content .slick-arrow.next {
        left: auto;
        right: -30px; } }
    .testimonial_content .slick-arrow.next i {
      border-radius: 5px;
      border-bottom-left-radius: 0; }

/*===========================
        10.BRAND css 
===========================*/
.single_logo img {
  border-radius: 50%;
  -webkit-box-shadow: 0px 3px 24px 0px rgba(213, 213, 213, 0.49);
  -moz-box-shadow: 0px 3px 24px 0px rgba(213, 213, 213, 0.49);
  box-shadow: 0px 3px 24px 0px rgba(213, 213, 213, 0.49);
  width: 100%; }

.single_logo.logo_2 {
  padding-top: 158px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single_logo.logo_2 {
      padding-top: 130px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_logo.logo_2 {
      padding-top: 90px; } }
  @media (max-width: 767px) {
    .single_logo.logo_2 {
      padding-top: 0; } }

/*===========================
       11.CONTACT css 
===========================*/
p.form-message.success, p.form-message.error {
  font-size: 16px;
  color: #9F8768;
  background: #f7f7fd;
  padding: 10px 15px;
  margin-top: 30px; }
  p.form-message.success.form-message.error, p.form-message.error.form-message.error {
    color: #f00; }

.contact_form .single_form {
  margin-top: 30px; }
  .contact_form .single_form textarea,
  .contact_form .single_form input {
    width: 100%;
    height: 55px;
    padding: 0 25px;
    border: 1px solid #677284;
    border-radius: 5px; }
    .contact_form .single_form textarea:focus,
    .contact_form .single_form input:focus {
      border: 1px solid #8b9474ff; }
  .contact_form .single_form textarea {
    padding-top: 15px;
    resize: none;
    height: 125px; }

.contact_info p {
  margin-top: 15px; }

.single_info {
  margin-top: 30px; }
  .single_info .info_icon i {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 20px;
    border-radius: 5px;
    background-color: #8b9474ff;
    color: #fff; }
    @media (max-width: 767px) {
      .single_info .info_icon i {
        width: 35px;
        height: 35px;
        line-height: 35px; } }
  .single_info .info_content {
    padding-left: 15px; }
    .single_info .info_content p {
      margin-top: 0; }
      @media (max-width: 767px) {
        .single_info .info_content p {
          font-size: 14px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .single_info .info_content p {
          font-size: 16px; } }

/*===========================
       12.FOOTER css 
===========================*/
.footer_area {
  position: relative;
  z-index: 5;
  overflow: hidden;
  border-top-left-radius: 150px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer_area {
      border-top-left-radius: 80px; } }
  @media (max-width: 767px) {
    .footer_area {
      border-top-left-radius: 60px; } }
  .footer_area::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    background-color: #8b9474ff;
    width: 100%;
    height: 100%;
    z-index: -1; }
.footer_about img{max-width: 50%;}
.footer_about p {
  margin-top: 25px;
  color: #fff; }

.footer_title {
  font-size: 30px;
  font-weight: 600;
  color: #fff; }

.footer_link_wrapper .footer_link {
  width: 33.33%; }
  @media (max-width: 767px) {
    .footer_link_wrapper .footer_link {
      width: 100%; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .footer_link_wrapper .footer_link {
      width: 50%; } }
  .footer_link_wrapper .footer_link .link {
    padding-top: 25px; }
    .footer_link_wrapper .footer_link .link li {
      margin-top: 10px; }
      .footer_link_wrapper .footer_link .link li a {
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s; }
        .footer_link_wrapper .footer_link .link li a:hover {
          color: #8b9474ff; }

.footer_copyright {
  border-top: 1px solid rgba(255, 255, 255, 0.58);
  padding: 25px 0; }
  .footer_copyright p {
    color: #fff; }

/*===== BACK TO TOP =====*/
.back-to-top {
  font-size: 20px;
  color: #fff;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  background-color: #8b9474ff;
  text-align: center;
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  display: none; }
  .back-to-top:hover {
    color: #fff;
    background-color: #8b9474ff; }
